<template>
  <RightSideBar
    v-if="openModal"
    :loading="loading"
    @submit="handleSubmit"
    submit="Submit"
    @close="handleClose"
    button-class="bg-dynamicBackBtn text-white"
    :disabled-button="isDisabled || isLoading"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        Disengage Employee
      </p>
    </template>
    <template v-slot:subtitle>
      <employee-details :user-details="userDetails" />
    </template>
    <div>
      <div class="flex mb-4">
        <p class="text-jet text-sm mr-5">
          Effective Date
        </p>
      </div>
      <div class="date-flex">
        <datepicker
          placeholder="--Start Date--"
          input-class="date-input "
          style="width:100%;"
          v-model="disengagePayload.effectiveDate"
        />
      </div>
      <divider border-dash class="my-4" />

      <div
        class="grid grid-cols-12 gap-4 text-darkPurple"
        style="margin-top:1.125rem"
      >
        <div class="col-span-12">
          <combobox
            placeholder="--Select--"
            :items="suspensionReasons"
            label="Reason for disengagement"
            variant="w-full"
            v-model="disengagePayload.reasonId"
            @change="isExisitingReason = true"
          />
        </div>
        <div class="col-span-12 ">
          <label class="date-label">Additional Comments</label>
          <!-- TODO: Fix text area in cloundly ui -->
          <c-textarea
            placeholder="--comment--"
            :col="50"
            type="textarea"
            v-model="disengagePayload.comments"
          />
        </div>
      </div>
    </div>
  </RightSideBar>
</template>

<script>
export default {
  name: "DisengageEmployee",
  components: {
    RightSideBar: () => import("@/components/RightSideBar"),
    combobox: () => import("@scelloo/cloudenly-ui/src/components/combobox"),
    CTextarea: () => import("@scelloo/cloudenly-ui/src/components/text-area"),
    EmployeeDetails: () =>
      import("../../EmployeeDirectory/sideActions/employeeDetails"),
    Datepicker: () => import("vuejs-datepicker"),
    Divider: () => import("@/components/divider")
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    userDetails: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      suspensionReasons: [],
      isExisitingReason: false,
      today: new Date(),
      isDisabled: true,
      disengagePayload: {
        effectiveDate: null,
        reasonId: null,
        comments: null,
        extendBy: 1,
        additionalComment: null
      },
      loading: true
    };
  },
  computed: {},
  methods: {
    handleIndefinite() {},
    handleClose() {
      this.disengagePayload.effectiveDate = null;
      this.disengagePayload.reasonId = null;
      this.disengagePayload.comments = null;
      this.$emit("close");
    },
    handleSubmit() {
      const payload = {
        extendBy: 1,
        orgId: this.$orgId,
        reasonId: this.disengagePayload.reasonId,
        comments: this.disengagePayload.comments,
        confirmUserId: this.userDetails.userId,
        additionalComment: this.disengagePayload.comments,
        payrollStatus: this.userDetails.payrollStatus === 'active',
        effectiveDate: this.$DATEFORMAT(
        new Date(this.disengagePayload.effectiveDate),
        "yyyy-MM-dd"
      ),
        initiatedBy: this.$AuthUser.id
      }

      this.disengagePayload.confirmUserId = this.userDetails.userId;
      this.disengagePayload.payrollStatus = this.userDetails.payrollStatus === 'active';
      this.disengagePayload.orgId = this.$AuthUser.orgId;
      this.disengagePayload.effectiveDate = this.$DATEFORMAT(
        new Date(this.disengagePayload.effectiveDate),
        "yyyy-MM-dd"
      );

      const result = this.suspensionReasons.find(
        item => item.id === this.disengagePayload.reasonId
      );

      if (result) {
        this.submitPayload(payload);
      } else {
        this.$_suspensionReason({
          orgId: this.disengagePayload.orgId,
          reason: this.disengagePayload.reasonId
        }).then(({ data }) => {
          this.disengagePayload.reasonId = data.reason.id;
          this.submitPayload(payload);
        });
      }
    },
    async submitPayload(payload) {
      await this.$_disengageOrgEmployee(payload).then(({ data }) => {
        const message = "Employee disengage action has been sent for Approval"
        this.$toasted.success(message, {duration: 3000})
        this.$emit('close', data)
      }).catch((error) => {
        this.$toasted.error(error, {duration: 3000})
        this.payload = {}
        this.isLoading = false
      })
    }
  },
  watch: {
    openModal() {
      if (this.openModal) {
        this.$_getSuspensionReason().then(result => {
          this.suspensionReasons = result.data.reasons.map(item => ({
            value: item.reason,
            ...item
          }));
          this.loading = false;
        });
      }
    },
    disengagePayload: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value.effectiveDate && value.reasonId) {
          this.isDisabled = false
        } else this.isDisabled = true
      }
    }
  }
};
</script>

<style lang="scss"></style>
